import React from "react";
import * as styles from "./customerReview.module.scss";

import { StaticImage } from "gatsby-plugin-image";

const CustomerReview = () => (
  <div className={styles.blueSection}>
    <div className={styles.orangePart}></div>
    <div className={styles.partnerSectionContainer}>
      <div className={styles.ekomiWrapper}>
        <div className={styles.orangeSectionGroup}>
          <StaticImage
            imgClassName={styles.ekomiLogo}
            src={"../../images/ekomi.png"}
            alt="ekomiLogo"
          />
          <div className={styles.whiteTextWrapper}>
            <span>Mehr als 5000 zufriedene Kunden</span>
          </div>
        </div>
        <div className={styles.bubbleArrowContainer}>
          <div className={styles.bubbleArrowLeft}></div>
        </div>
        <div className={styles.bubbleSpeech}>
          <StaticImage
            alt="Speach background"
            imgClassName={styles.bubleSpeachBackground}
            src={"../../images/bubleSpeach.png"}
          />
          <div className={styles.bubbleSpeechText}>
            <span>17.03.2019 um 12:28 Uhr</span> Absolut empfehlenswert. Habe
            kurz bei umliegenden Schrotthändlern per Telefon nachgefragt. Alle
            haben Preise um die 10% niedriger als bei Schrott 24 angeboten. Geld
            war in 2 Tagen auf dem Konto!
          </div>
          <div className={styles.bubbleSpeechRateGroup}>
            <div className={styles.starsGroup}>
              {[...Array(5).keys()].map(n => (
                <StaticImage
                  imgClassName={styles.star}
                  key={`star${n}`}
                  alt={`star${n}`}
                  src={"../../images/star.png"}
                />
              ))}
            </div>
            <StaticImage
              imgClassName={styles.score5}
              alt="star"
              src={"../../images/5of5.png"}
            />
            <a
              className={styles.yellowTransparentButton}
              href="/ewertungen-schrottwolf-eisen-metalle-maschinen-handelsges-mbh"
              target="_blank"
            >
              MEHR
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CustomerReview;
