import React, { useContext } from "react";
import * as styles from "./deliveryMethod.module.scss";
import DomainContext from "../../context/DomainContext";

import { StaticImage } from "gatsby-plugin-image";

const deliveryMethods = {
  common: [
    {
      Image: ({ className }) => (
        <StaticImage
          src="../../images/delivery-1.png"
          alt="Delivery option 1"
          className={className + " " + styles.delivery1}
        />
      ),
      alt: "Delivery option 1",
      text: "Abholung mit Container",
    },
    {
      Image: ({ className }) => (
        <StaticImage
          src="../../images/delivery-2.png"
          alt="Delivery option 2"
          className={className + " " + styles.delivery1}
        />
      ),
      alt: "Delivery option 2",
      text: "Abholung mit Greifer LKW",
    },
    {
      Image: ({ className }) => (
        <StaticImage
          src="../../images/delivery-3.png"
          alt="Delivery option 3"
          className={className + " " + styles.delivery3}
        />
      ),
      alt: "Delivery option 3",
      text: "Abholung mit Big Bag",
    },
    {
      Image: ({ className }) => (
        <StaticImage
          src="../../images/delivery-4.png"
          alt="Delivery option 4"
          className={className + " " + styles.delivery4}
        />
      ),
      alt: "Delivery option 4",
      text: "Abholung auf Palette",
    },
    {
      Image: ({ className }) => (
        <StaticImage
          src="../../images/delivery-5.png"
          alt="Delivery option 5"
          className={className}
        />
      ),
      alt: "Delivery option 5",
      text: "Eigenanlieferung",
    },
    {
      Image: ({ className }) => (
        <StaticImage
          src="../../images/delivery-6.png"
          alt="Delivery option 6"
          className={className}
        />
      ),
      alt: "Delivery option 6",
      text: "Paketversand",
    },
  ],
  ch: [
    {
      Image: ({ className }) => (
        <StaticImage
          src="../../images/delivery-1.png"
          alt="Delivery option 1"
          className={className + " " + styles.delivery1}
        />
      ),
      alt: "Delivery option 1",
      text: "Abholung mit Container",
    },
    {
      Image: ({ className }) => (
        <StaticImage
          src="../../images/delivery-2.png"
          alt="Delivery option 2"
          className={className + " " + styles.delivery2}
        />
      ),
      alt: "Delivery option 2",
      text: "Vollladung",
    },
    {
      Image: ({ className }) => (
        <StaticImage
          src="../../images/delivery-3.png"
          alt="Delivery option 3"
          className={className + " " + styles.delivery3}
        />
      ),
      alt: "Delivery option 3",
      text: "Abholung mit Big Bag",
    },
    {
      Image: ({ className }) => (
        <StaticImage
          src="../../images/delivery-4.png"
          alt="Delivery option 4"
          className={className + " " + styles.delivery4}
        /> 
      ),
      alt: "Delivery option 4",
      text: "Abholung auf Palette",
    },
  ],
};

const DeliveryMethod = () => {
  const domain = useContext(DomainContext);
  const finalLabels = deliveryMethods[domain] || deliveryMethods["common"];

  return (
    <div className={styles.pageSection}>
      <div className={styles.container}>
        <div className={`${styles.group} ${domain === "ch" && styles.chGroup}`}>
          {finalLabels.map(item => (
            <div className={styles.deliveryMethod}>
              <div>
                <item.Image
                  className={`${styles.image} ${domain === "ch" &&
                    styles.chImage}`}
                />
              </div>
              <span className={styles.text}>{item.text}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.orangeButton}>
        <a href="/ablauf/#transportoptionen-detail-wrapper-ablauf">
          MEHR INFORMATIONEN
        </a>
      </div>
    </div>
  );
};

export default DeliveryMethod;
