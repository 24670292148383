import React from "react";
import * as styles from "./logoGroupReview.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const LogoGroupReview = () => (
  <div className={styles.ekomiBanner}>
    <div className={styles.partnerLogoContainer}>
      <h4 className={styles.heading}>Mehr als 5000 zufriedene Kunden.</h4>
      <div className={styles.partnerLogosGroup}>
        <StaticImage
          alt="siemens"
          className={styles.iconWrapper + " " + styles.iconWrapperSiemens}
          imgClassName={styles.icon + " " + styles.iconSiemens}
          src={"../../images/EkomiBanner-Siemens.png"}
        />
        <StaticImage
          alt="onb"
          className={styles.iconWrapper + " " + styles.iconWrapperOng}
          imgClassName={styles.icon + " " + styles.iconOnb}
          src={"../../images/EkomiBanner-ONB.png"}
        />
        <StaticImage
          alt="straub"
          className={styles.iconWrapper + " " + styles.iconWrapperStraub}
          imgClassName={styles.icon + " " + styles.iconStraub}
          src={"../../images/EkomiBanner-Straub.png"}
        />
        <StaticImage
          alt="rewe"
          className={styles.iconWrapper + " " + styles.iconWrapperRewe}
          imgClassName={styles.icon + " " + styles.iconRewe}
          src={"../../images/EkomiBanner-Rewe.png"}
        />
        <StaticImage
          alt="sebring"
          className={styles.iconWrapper + " " + styles.iconWrapperSebring}
          imgClassName={styles.icon + " " + styles.iconSebring}
          src={"../../images/EkomiBanner-sebring.png"}
        />
      </div>
    </div>
    <div className={styles.ekomiLogoContainer}>
      <StaticImage
        alt="ekomiLogo"
        className={styles.logoWrapper}
        imgClassName={styles.logo}
        src={"../../images/EkomiBanner-ekomiLogo.png"}
      />
      <StaticImage
        alt="score"
        className={styles.scoreWrapper}
        imgClassName={styles.score}
        src={"../../images/EkomiBanner-score.png"}
      />
    </div>
  </div>
);

export default LogoGroupReview;
