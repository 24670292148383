import React, { useContext, useState } from "react";
import * as styles from "./howItWorks.module.scss";
import DomainContext from "../../context/DomainContext";
import { StaticImage } from "gatsby-plugin-image";

const labels = {
  ch: {
    buttonLabel: "Über uns",
  },
  common: {
    buttonLabel: "So funktioniert's",
  },
};

const HowItWorks = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openVideo = () => setIsModalOpen(true);
  const closeVideo = () => setIsModalOpen(false);
  const domain = useContext(DomainContext);
  const finalLabels = { ...labels["common"], ...(labels[domain] || {}) };

  return (
    <div className={styles.homePageVideoSection}>
      <div className={styles.howDoesItWorksSection}>
        <div className={styles.headerMobile}>
          <span className={styles.support}>{finalLabels.buttonLabel}</span>
        </div>
        <div className={styles.homePageVideo}>
          <StaticImage
            imgClassName={styles.image}
            alt="video thumbnail"
            src={"../../images/homePageVideo.svg"}
            id="homePageVideo"
            onClick={openVideo}
          />
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.templateHideMobile}>
            <span className={styles.support}>{finalLabels.buttonLabel}</span>
          </div>
          <div className={styles.orangeButton}>
            <button onClick={openVideo} className={styles.playHomeVideo}>
              VIDEO ANSCHAUEN
            </button>
          </div>
        </div>
      </div>
      {isModalOpen ? (
        <div className={styles.videoWrapper} onClick={closeVideo}>
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <span onClick={closeVideo} className={styles.close}>
                ×
              </span>
              <iframe
                title="how it works"
                width="560"
                height="315"
                id="homeVideo"
                src="https://www.youtube.com/embed/76CdWnK6Q60?controls=0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                data-gtm-vis-recent-on-screen-1451232_19="221518"
                data-gtm-vis-first-on-screen-1451232_19="221518"
                data-gtm-vis-total-visible-time-1451232_19="100"
                data-gtm-vis-has-fired-1451232_19="1"
                data-gtm-vis-recent-on-screen-1451232_16="221531"
                data-gtm-vis-first-on-screen-1451232_16="221531"
                data-gtm-vis-total-visible-time-1451232_16="100"
                data-gtm-vis-has-fired-1451232_16="1"
              ></iframe>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default HowItWorks;
