import React, { useContext } from "react";
import DoneIcon from "@material-ui/icons/Done";
import * as styles from "./availableFacilities.module.scss";
import DomainContext from "../../context/DomainContext";

const facilities = {
  common: [
    "80 Standorte",
    "Alle Transportmöglichkeiten",
    "48h Express-Auszahlung",
  ], 
  ch: ["Bessere Preise", "All-in-One Service", "Volle Sicherheit"],
};

const AvailableFacilities = () => {
  const domain = useContext(DomainContext);
  const finalLabels = facilities[domain] || facilities["common"];

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        {finalLabels.map(item => (
          <div className={styles.card} key={item}>
            <DoneIcon className={styles.icon} />
            <span className={styles.text}>{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvailableFacilities;
