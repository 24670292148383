import React, { useContext } from "react";
import * as styles from "./stepsToFollow.module.scss";
import DomainContext from "../../context/DomainContext";

import one from "../../images/stepBullet1.svg";
import two from "../../images/stepBullet2.svg";
import three from "../../images/stepBullet3.svg";
import { StaticImage } from "gatsby-plugin-image";

const labels = {
  common: {
    heading:
      "Altmetall zum aktuellen Schrottpreis verkaufen — in nur 3 Schritten",
    description: [
      "Geschätztes Gewicht eingeben und das Material mit dem vorläufigen Preis in den Verkaufskorb legen.",
      "Im Checkout wird der Preis anhand Ihrer gewählten Optionen noch final angepasst.",
      " Sie erhalten Ihr Geld nach der Qualitätsprüfung des Altmetalls nach ca. 48h per PayPal oder Banküberweisung.",
    ],
    title: [
      "Schrottsorte wählen",
      "Transport und Standort wählen",
      "Express-Auszahlung",
    ],
    buttonLabel: "SCHROTTPREIS BERECHNEN",
  },
  ch: {
    heading:
      "Individuelle Recyclinglösung zum besten Preis — in nur 3 Schritten",
    description: [
      "Formular ausfüllen und wir qualifizieren Ihre Anfrage.",
      "Wir finden die beste Recyclinglösung.",
      "Sie erhalten ein unverbindliches Verwertungsangebot.",
    ],
    title: [],
    buttonLabel: "Angebot erhalten",
  },
};

const StepsToFollow = () => {
  const domain = useContext(DomainContext);
  const finalLabels = { ...labels["common"], ...(labels[domain] || {}) };

  return (
    <div className={styles.container}>
      <span className={styles.howDoesItWorks}>So funktioniert's</span>
      <h2 className={styles.heading}>{finalLabels.heading}</h2>
      <div className={styles.mobileContainer}>
        <div className={styles.stepGroupImagesContainer}>
          <StaticImage
            className={styles.stepIcon}
            src={"../../images/stepBullet1.svg"}
            alt="step1"
          />
          <div className={styles.greyLineSeparator}></div>
          <StaticImage
            className={styles.stepIcon}
            src={"../../images/stepBullet2.svg"}
            alt="step2"
          />
          <div className={styles.greyLineSeparator}></div>
          <StaticImage
            className={styles.stepIcon}
            src={"../../images/stepBullet3.svg"}
            alt="step3"
          />
        </div>
        <div className={styles.stepGroupContainer}>
          {[...Array(3).keys()].map(i => (
            <div className={styles.stepGroupTextContainer}>
              {!labels[domain] && (
                <label className={styles.title}>{finalLabels.title[i]}</label>
              )}
              <p>{finalLabels.description[i]}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.orangeButton}>
        <a href="/pro">{finalLabels.buttonLabel}</a>
      </div>
    </div>
  );
};

export default StepsToFollow;
