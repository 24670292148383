import React from "react";
import * as styles from "./vortheleBanner.module.scss";

import notebook from "../../images/notebook_701.png";
import { StaticImage } from "gatsby-plugin-image";

const VortheleBanner = () => (
  <div className={styles.outerContainer}>
    <div className={styles.ihreSectionBanner}>
      <div className={styles.ihreSectionContainer}>
        <span className={styles.allInOne}> All-in-One Service</span>
        <h2 className={styles.mainHeading}> Ihre Vorteile</h2>
        <StaticImage
          className={styles.hidePc}
          src={"../../images/notebook_701.png"}
          alt="hidepc"
        />
        <h3 className={styles.subHeading}> Industrie &amp; Unternehmen</h3>
        <ul className={styles.list}>
          <li className={styles.labelList}>
            <label className={styles.label}>
              {" "}
              Individuelles Angebot und Beratung
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Europaweites Netzwerk aus Aufbereitungsanlagen und Schmelzwerken
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Fokus auf Prozess- und Erlösoptimierung
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Beste Preise durch dynamisches Matching mit Angebot und Nachfrage
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              All-in-One Service vom Angebot, Logistik bis zur Auszahlung
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Sichere und verlässliche Abwicklung
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Containerservice, Demontagen, Spotverkäufe
            </label>
          </li>
        </ul>
        <a className={styles.button} href="/pro">
          Angebot erhalten
        </a>
      </div>
      <div className={styles.imageContainer}>
        <StaticImage
          className={styles.noteBook}
          src={"../../images/notebook_701.png"}
          alt="notebook"
        />
      </div>
    </div>
  </div>
);

export default VortheleBanner;
