import React, { useContext } from "react";
import styles from "./deliveryMap.module.scss";
import DomainContext from "../../../context/DomainContext";
import EuroSwissMapMobile from "../../../images/europeanBannerSwiss-Map-Mobile.png";
import EuroSwissMapTablet from "../../../images/europeanBannerSwiss-Map-Tablet.png";
import EuroSwissMap from "../../../images/map-swiss.svg";
import GermanyMap from "../../../images/map-germany.png";
import AustrianMap from "../../../images/Austrian-map.png";

const maps = {
  at: AustrianMap,
  de: GermanyMap,
};

const getCommonMap = domain => (
  <div className={styles.mapSectionBanner}>
    <div className={styles.mapSection}>
      <div className={styles.textContainer}>
        <span className={styles.title}>
          Mehr als 80 Anlieferstellen, auch in Ihrer Nähe!?
        </span>
        <span className={styles.subTitle}>
          Verkaufen Sie Ihr Altmetall zu Bestpreisen.
        </span>
        <div className={styles.orangeButton}>
          <a className={styles.deliveryButton} href="/standorte/">
            ZU DEN ANLIEFERSTELLEN
          </a>
        </div>
      </div>
      <div className={styles.mapContainer}>
        <img
          alt="delivery map"
          className={styles.mapImage}
          src={maps[domain]}
        />
      </div>
    </div>
  </div>
);

const getCH = () => (
  <div className={styles.euroMapSectionBanner}>
    <div className={styles.euroMapTextContainer}>
      <div className={styles.euroMapText}>
        <span className={styles.euroTitle}>Globales Netzwerk</span>
        <h2 className={styles.euroHeading}>
          So Profitieren Sie als Industrie-Kunde
        </h2>
        <p className={styles.euroPara}>
          Durch unser globales Netzwerk aus Recyclingspezialisten,
          Aufbereitungsanlagen und Transportunternehmen, findet Ihr Material den
          direktesten Weg zurück in den Materialkreislauf.
        </p>
        <p className={styles.euroPara}>
          So profitieren Sie nicht nur von sehr guten Preisen, sondern können
          auch auf eine reibungslose Abwicklung aus einer Hand zählen.
        </p>
        <a className={styles.deliveryButton} href="/anfrage/">
          VERKAUFEN SIE AN UNS
        </a>
      </div>
    </div>
    <div className={styles.euroMapBannerContainer}>
      <picture>
        <source
          media="(max-width: 768px)"
          sizes="100px"
          srcset={EuroSwissMapMobile}
        />
        <source
          media="(max-width: 1140px)"
          sizes="100px"
          srcset={EuroSwissMapTablet}
        />
        <img alt="delivery map" className="map-img-ch" src={EuroSwissMap} />
      </picture>
    </div>
  </div>
);

const DeliveryMap = () => {
  const domain = useContext(DomainContext);
  switch (domain) {
    case "ch":
      return getCH();
    case "de":
      return getCommonMap("de");
    case "at":
    default:
      return getCommonMap("at"); // fallback map
  }
};

export default DeliveryMap;
