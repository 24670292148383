import React from "react";
import * as styles from "./featureList.module.scss";

import homepageListFirst from "../../images/homepageListFirst.svg";
import homepageListSecond from "../../images/homepageListSecond.svg";
import { StaticImage } from "gatsby-plugin-image";

const FeatureList = () => (
  <div className={styles.container}>
    <div className={styles.title}>
      <span className={styles.homeSectionTitle}>Altmetall verkaufen</span>
    </div>
    <div className={styles.blueBackgroundLine}>
      <div className={styles.blueBackgroundsquare}></div>
      <div className={styles.blueBackground}></div>
    </div>
    <div className={styles.whiteBackground}>
      <div className={styles.firstcolumn}>
        <StaticImage
          alt="homepagefirst"
          imgClassName={styles.firstImage}
          src={"../../images/homepageListFirst.svg"}
        />
        <span className={styles.firstHeading}> Gewerbe &amp; Privat</span>
        <ul className={styles.list}>
          <li className={styles.labelList}>
            <label className={styles.label}> Tagesaktuelle Schrottpreise</label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>Einfacher Verkauf im Webshop</label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Bessere Preise durch Partner-Netzwerk
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              6 verschiedene Transportarten
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}> Über 80 Anlieferstellen</label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Dokumentierte Qualitäts- und Gewichtskontrolle
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}> Rasche Auszahlung</label>
          </li>
        </ul>
        <a className={styles.bottomBox} href="/schrottpreise">
          Schrottpreise ansehen
        </a>
      </div>
      <div className={styles.secondcolumn}>
        <StaticImage
          alt="homepagesecond"
          imgClassName={styles.secondImage}
          src={"../../images/homepageListSecond.svg"}
        />
        <span className={styles.secondHeading}>
          Industrie &amp; Unternehmen
        </span>
        <ul className={styles.list}>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Individuelles Angebot und Beratung
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Europaweites Netzwerk aus Aufbereitungsanlagen und Schmelzwerken
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Fokus auf Prozess- und Erlösoptimierung
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Beste Preise durch dynamisches Matching mit Angebot und Nachfrage
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              All-in-One Service vom Angebot, Logistik bis zur Auszahlung
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Sichere und verlässliche Abwicklung
            </label>
          </li>
          <li className={styles.labelList}>
            <label className={styles.label}>
              Containerservice, Demontagen, Spotverkäufe
            </label>
          </li>
        </ul>
        <a className={styles.bottomBox2} href="/anfrage/">
          Angebot erhalten
        </a>
      </div>
    </div>
  </div>
);

export default FeatureList;
