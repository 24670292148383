import React, { useContext } from "react";
import NavMenu from "../../components/nav-menu/NavMenu";
import Helmet from "react-helmet";
import StepsToFollow from "../../components/steps-to-follow/StepsToFollow";
import LogoGroup from "../../components/logo-group/LogoGroup";
import HowItWorks from "../../components/how-it-works/HowItWorks";
import OptimizeScrap from "../../components/optimize-scrap/OptimizeScrap";
import OpinionSlider from "../../components/opinion-slider/OpinionSlider";

import IncreaseRevenue from "../../components/main-page/sections/increaseRevenue/IncreaseRevenue";

import MarketUpdatePopUp from "../../components/main-page/popUp/marketUpdatePopUp";
import FeatureList from "../../components/feature-list/FeatureList";
import HomepageWidget from "../../components/homepage-widget/HomepageWidget";

import DeliveryMap from "../../components/map-delivery-points/DeliveryMap";
import Footer from "../../components/main-page/sections/footer/Footer";
import AvailableFacilities from "../../components/available-facilities/AvailableFacilities";

import styles from "./index.module.scss";
import * as actions from "../../action";
import { connect } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n/i18n";
import DomainContext from "../../context/DomainContext";
import SEOComponent from "../../components/Shared/Seo";

import DeliveryMethod from "../../components/delivery-method/DeliveryMethod";
import CustomerReview from "../../components/customer-review/CustomerReview";
import GroupLegal from "../../components/group-legal/GroupLegal";
import LogoGroupReview from "../../components/logo-group-review/LogoGroupReview";
import VortheleBanner from "../../components/vorthele-banner/VortheleBanner";
import MetaloopBanner from "../../components/metaloop-banner/metaloopBanner";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elementsLoaded: 0,
      homeUrl: "",
      showEuropeanMap: false,
    };
    this.setElementsLoad = this.setElementsLoad.bind(this);
  }

  componentDidMount() {
    this.props.loadInitialInfo();
    this.props.getProductList();
    const sitesShowEuropeanMap = ["ch"];
    let homeUrl = window.location.origin;
    let site = homeUrl.split("schrott24.");
    let showEuropeanMap = false;

    if (site.length > 1) {
      site = site[1].substring(0, 2);
      showEuropeanMap =
        sitesShowEuropeanMap.filter(s => s.includes(site)).length > 0;
    }

    this.setState({ homeUrl: homeUrl, showEuropeanMap: showEuropeanMap });
  }

  setElementsLoad(val) {
    this.setState({ elementsLoaded: val });
  }

  render() {
    const { productList, getProductData, productData, domain } = this.props;

    return (
      <I18nextProvider i18n={i18n} defaultNS={"default"}>
        <SEOComponent page="home" />
        <NavMenu />
        <MetaloopBanner />

        <HomepageWidget
          productList={productList}
          getProductData={getProductData}
          productData={productData}
        />
        <AvailableFacilities />
        <DeliveryMethod />
        {domain === "ch" ? (
          <LogoGroupReview />
        ) : (
          <>
            <CustomerReview />
            <LogoGroup />
          </>
        )}
        <HowItWorks />
        <StepsToFollow />
        <DeliveryMap />
        <OptimizeScrap />

        <OpinionSlider />
        {domain === "ch" ? <VortheleBanner /> : <FeatureList />}
        <Footer domain={domain} />
        <GroupLegal />
      </I18nextProvider>
    );
  }
}

function mapStateToProps({ leadForm, products }) {
  return {
    productList: products.productList,
    productData: products.productData,
  };
}
export function IndexWithContext(props) {
  const domain = useContext(DomainContext);

  return <IndexPage {...props} domain={domain} />;
}

export default connect(mapStateToProps, actions)(IndexWithContext);
