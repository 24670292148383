import React, { useContext } from "react";
import DomainContext from "../../context/DomainContext";
import * as styles from "./logoGroup.module.scss";

import onb from "../../images/onb_logo.svg";
import rewe from "../../images/rewe_logo.svg";
import sebring from "../../images/sebring_logo.svg";
import siemens from "../../images/siemens_logo.svg";
import straub from "../../images/straub_logo.svg";
import { StaticImage } from "gatsby-plugin-image";

const LogoGroup = () => {
  const domain = useContext(DomainContext);
  return (
    <div className={styles.logoGroupContainer}>
      <label className={styles.label}>
        {domain === "ch" && "Mehr als 5000 zufriedene Kunden."}
      </label>
      <div className={styles.logoGroup}>
        <div className={styles.secondContainer}>
          <StaticImage
            alt="siemens"
            imgClassName={styles.icon}
            src={"../../images/siemens_logo.svg"}
          />
          <StaticImage
            alt="onb"
            imgClassName={styles.icon}
            src={"../../images/onb_logo.svg"}
          />
          <StaticImage
            alt="straub"
            imgClassName={styles.icon}
            src={"../../images/straub_logo.svg"}
          />
          <StaticImage
            alt="rewe"
            imgClassName={styles.icon}
            src={"../../images/rewe_logo.svg"}
          />
          <StaticImage
            alt="sebring"
            imgClassName={styles.icon}
            src={"../../images/sebring_logo.svg"}
          />
        </div>
      </div>
    </div>
  );
};

export default LogoGroup;
